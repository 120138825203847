import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage from '../components/text-page';

const BoardOfExpertise = () => {
  const intl = useIntl();
  const messages = intl.messages;

  return (<TextPage locale={intl.locale} title={`${messages.board_of_expertise}`}/>);
};

export default BoardOfExpertise;